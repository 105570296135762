<div class="row">
  <div class="modal-body">
    <div class="close-btn" (click)="cancel()">
      <img src="assets/images/closeIcon.svg" alt="">
    </div>
    <div class="social-title">
      {{ title }}
    </div>
    <div class="confirm-body">
      {{ message }}
    </div>

  </div>
  <div class="confirm-footer">
    <button type="button" class="btn common-btn grn" (click)="cancel()">{{ cancelText }}</button>
    <button type="button" class="btn common-btn grn" (click)="confirm()">{{ confirmText }}</button>
  </div>
</div>