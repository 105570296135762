import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [TranslatePipe],
  providers:[TranslatePipe],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {
  @Input() title: string = 'Are You Sure?';
  @Input() message: string = 'Are you sure you want to proceed?';
  @Input() confirmText: string = 'Yes';
  @Input() cancelText: string = 'No';

  constructor(public activeModal: NgbActiveModal) {}

  confirm(): void {
    this.activeModal.close(true); // Pass true to the close method to indicate confirmation
  }

  cancel(): void {
    this.activeModal.dismiss(false); // Pass false to the dismiss method to indicate cancellation
  }
}
